<template>
    <div class="container">
        <div class="search" style="display: flex">
            <el-form
                ref="queryFormRef"
                style="flex: 1"
                :inline="true"
                :model="search"
                class="demo-form-inline"
                size="mini"
            >
                <el-form-item label="型号：" prop="model_code">
                    <SearchInnerModel
                        v-model="search.model_code"
                        @selectQuery="Search"
                    ></SearchInnerModel>
                </el-form-item>

                <el-form-item>
                    <el-button
                        type="primary"
                        style="margin-left: 16px"
                        class="btnClass"
                        @click="Search"
                        >搜索</el-button
                    >
                    <el-button
                        type="text"
                        class="textBtn"
                        style="padding-bottom: 0px; vertical-align: bottom"
                        @click="clearCondition"
                        >重置</el-button
                    >
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button
                        type="primary"
                        style="margin-left: 16px"
                        class="btnClass"
                        @click="add"
                        >新增</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <!-- 列表 -->
        <el-table :data="list" size="mini">
            <el-table-column label="设备型号" width="120">
                <template v-slot="scope">
                    <span>{{ scope.row.model_code }}</span>
                </template>
            </el-table-column>
            <el-table-column label="国家简写" width="90">
                <template v-slot="scope">
                    <span>{{ scope.row.country }}</span>
                </template>
            </el-table-column>
            <el-table-column label="国家名称" width="90">
                <template v-slot="scope">
                    <span>{{ scope.row.country_name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="链接">
                <template v-slot="scope">
                    <span>{{ scope.row.comment_url }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="创建时间(UTC)"
                width="170"
                prop="create_time"
            >
                <template v-slot="scope">
                    <span>{{ scope.row.created_at }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="更新时间(UTC)"
                width="170"
                prop="updated_at"
            >
                <template v-slot="scope">
                    <span>{{ scope.row.updated_at }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template v-slot="scope">
                    <el-button
                        type="info"
                        size="mini"
                        class="btnClass"
                        @click="update(scope.row)"
                        >修改</el-button
                    >
                    <el-button type="danger" size="mini" @click="del(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            v-model:currentPage="search.page"
            :page-size="search.limit"
            layout="total, prev, pager, next"
            :total="total"
            background
        >
        </el-pagination>
        <el-dialog
            v-model="updateDialog"
            :modal="true"
            :append-to-body="true"
            title="修改"
            width="30%"
            size="mini"
        >
            <el-form
                class="modify-uuid"
                size="mini"
                label-width="auto"
                :rules="updateRules"
                ref="updateForm"
                :model="updateForm"
            >
                <el-form-item label="链接: " prop="comment_url">
                    <el-input v-model="updateForm.comment_url" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="mini" @click="updateDialog = false"
                        >取消</el-button
                    >
                    <el-button
                        type="primary"
                        size="mini"
                        @click="updateComment()"
                        >确定</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="addDialog"
            :modal="true"
            :append-to-body="true"
            title="新增"
            width="30%"
            size="mini"
        >
            <el-form
                class="modify-uuid"
                size="mini"
                label-width="auto"
                :rules="addRules"
                ref="addForm"
                :model="addForm"
            >
                <el-form-item label="型号: " prop="model_code">
                    <SearchInnerModel
                        v-model="addForm.model_code"
                    ></SearchInnerModel>
                </el-form-item>
                <el-form-item label="国家: " prop="country">
                    <!-- <el-select v-model="addForm.country">
                        <el-option
                            v-for="item in countryOption"
                            :key="item.abbr"
                            :label="item.chinese"
                            :value="item.abbr"
                        >
                        </el-option>
                    </el-select> -->
                    <SearchCountryList
                        v-model="addForm.country"
                    ></SearchCountryList>
                </el-form-item>
                <el-form-item label="链接: " prop="comment_url">
                    <el-input v-model="addForm.comment_url" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="mini" @click="addDialog = false"
                        >取消</el-button
                    >
                    <el-button type="primary" size="mini" @click="addComment()"
                        >确定</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import { ElMessageBox } from "element-plus"
import util from "../../../util/util"
import SearchInnerModel from '../../../components/device/SearchInnerModel.vue'
import SearchCountryList from '../../../components/device/SearchCountryList.vue'

export default {
    name: "",
    mixins: [],
    components: {
        SearchInnerModel,
        SearchCountryList
    },
    props: {},
    data() {
        return {
            util: util,
            search: {
                page: 1,
                limit: 16,
                model_code: "",
            },
            list: [],
            total: 0,
            countryOption: [],
            modelOptions: [],
            addDialog: false,
            updateDialog: false,
            addForm: {
                model_code: "",
                comment_url: "",
                country: "",
                country_name: "",
            },
            updateForm: {
                comment_url: "",
            },
            currentRow: {},
            addRules: {
                model_code: [
                    { required: true, message: "请选择型号", trigger: "change" },
                ],
                country: [{ required: true, message: "请选择国家", trigger: "change" }],
                comment_url: [
                    { required: true, message: "请输入链接", trigger: "blur" },
                ],
            },
            updateRules: {
                comment_url: [
                    { required: true, message: "请输入链接", trigger: "blur" },
                ],
            },
        }
    },
    computed: {},
    watch: {},
    methods: {
        getTimestamp(date) {
            return +date.getTime().toString().slice(0, 10)
        },
        handleCurrentChange(val) {
            this.search.page = val
            this.Search(val)
        },
        Search(val) {
            this.api
                .InviteCommentList({
                    ...this.search,
                    page: typeof val === "number" ? val : 1,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.list = res.data.data.data
                        this.total = res.data.data.page_info.total
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        getCountryCode() {
            this.api
                .CountryList({
                    page: 1,
                    limit: 1000,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.countryOption = res.data.data.data
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        getModelList() {
            this.api
                .ProductModelList({
                    page: 1,
                    limit: 1000,
                    model: "",
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.modelOptions = res.data.data.data.map((item) => ({
                            value: item.code,
                            label: item.code,
                        }))
                        return
                    }
                    this.loading = false
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        add() {
            this.$refs.addForm?.resetFields()
            this.addDialog = true
        },
        update(row) {
            this.$refs.updateForm?.resetFields()
            this.currentRow = row
            this.updateDialog = true
        },
        del(row) {
            ElMessageBox.confirm("你确定要删除吗?", "警告", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.api
                        .InviteCommentDelete({
                            id: row.id,
                        })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success("删除成功")
                                this.Search()
                                return
                            }
                            this.$message.error("调用失败:" + res.data.msg)
                        })
                })
                .catch(() => {
                    this.$message.info("删除取消")
                })
        },
        addComment() {
            this.$refs.addForm.validate((valid) => {
                if (valid) {
                    const ctry = this.countryOption.find((v) => v.id === this.addForm.country)
                    const country_name = ctry ?.chinese ?? ""
                    const newForm = {
                        ...this.addForm,
                        country: ctry?.abbr ?? "",
                        country_name,
                    }
                    this.api.InviteCommentCreate(newForm).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("添加成功")
                            this.addDialog = false
                            this.Search()
                            return
                        }
                        this.$message.error("调用失败:" + res.data.msg)
                    })
                }
            })
        },
        updateComment() {
            this.$refs.updateForm.validate((valid) => {
                if (valid) {
                    this.api
                        .InviteCommentUpdate({
                            id: this.currentRow.id,
                            comment_url: this.updateForm.comment_url,
                        })
                        .then((res) => {
                            if (res.data.code == 200) {
                                this.$message.success("修改成功")
                                this.updateDialog = false
                                this.Search()
                                return
                            }
                            this.$message.error("调用失败:" + res.data.msg)
                        })
                }
            })
        },
        clearCondition() {
            this.reset()
            this.Search()
        },
        reset() {
            this.search = {
                ...this.search,
                model_code: "",
            }
        },
    },
    filters: {},
    mounted() {
        this.Search()
        this.getCountryCode()
        // this.getModelList()
    },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.container {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  .search {
    background: #F0F2F5;
    padding-top: 16px;
    margin-bottom: 24px;
  }

  .el-form {
    ::v-deep(.el-form-item__label) {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
      padding-right: 0px;
    }

    .el-form-item {
      margin-left: 24px;
    }
  }

  .btnClass {
    color: #FFF;
    background-color: #FB6D07;
    border-color: #FB6D07;
  }

  .textBtn {
    color: #FB6D07;
  }

  ::v-deep(thead) {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    opacity: 0.8;

    th {
      font-weight: 400;
      background-color: #F0F2F5;
    }
  }

  ::v-deep(tbody) {
    tr {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
    }
  }
}
</style>
