<template>
    <el-select
        v-model="model"
        filterable
        clearable
        remote
        reserve-keyword
        placeholder=" "
        :remote-method="remoteCountryList"
        :loading="loading"
        @change="handleChange"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
    </el-select>
</template>

<script>
export default {
    name: 'SearchCountryList',
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            loading: false,
            options: []
        }
    },
    created() {
        this.remoteCountryList()
    },
    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        remoteCountryList(query) {
            this.loading = true
            this.api
                .CountryList({
                    page: 1,
                    limit: 1000,
                    kname: query || ''
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        const { data } = res.data.data
                        this.options = data.map((item) => ({
                            value: item.id,
                            label: item.chinese
                        }))
                        this.loading = false
                        return
                    }
                    this.loading = false
                    this.$message.error('获取失败' + res.data.msg)
                })
        },
        handleChange() {
            this.$emit('selectQuery')
        }
    }
}
</script>
